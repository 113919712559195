// modal
let openButton = document.querySelector('#openModal');
let modal = document.querySelector('#modal');
let close = document.querySelector('.close');
let body = document.querySelector('body');

console.log(openButton);
console.log(modal);

openButton.addEventListener('click', function () {
    modal.classList.add('show-modal');
    body.style.overflow = 'hidden';
});

close.addEventListener('click', function () {
    modal.classList.remove('show-modal');
    body.style.overflow = 'auto';
});
